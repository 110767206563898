import theme from '@/style';
import {View, StyleSheet} from 'react-native';
import React from 'react';
import Text from '@/components/basic/text';
import LinearGradient from '@/components/basic/linear-gradient';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {useTranslation} from 'react-i18next';
import {BetsInfo} from './color-service';

export interface ColorQuickProps {
  onColorPress?: (info: BetsInfo) => void;
  disabled?: boolean;
}

const ColorQuick = ({
  onColorPress = () => {},
  disabled = false,
}: ColorQuickProps) => {
  const {i18n} = useTranslation();
  return (
    <LinearGradient colors={theme.backgroundColor.colorQuickLinearGradient}>
      <View
        style={[
          styles.border,
          // eslint-disable-next-line react-native/no-inline-styles
          {top: 'auto', bottom: 0},
        ]}
      />
      <View
        style={[
          theme.padding.xxl,
          theme.flex.row,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            gap: 8,
            paddingHorizontal: theme.paddingSize.xxl,
            paddingVertical: theme.paddingSize.l,
          },
        ]}>
        <LinearGradient
          colors={disabled ? ['#D2D2D2', '#888888'] : ['#27D44E', '#048D02']}
          style={[theme.flex.flex1, styles.left]}>
          <View style={[styles.border]} />
          <NativeTouchableOpacity
            disabled={disabled}
            onPress={() => {
              onColorPress({
                digit: 'g',
                color: 'g',
                onlyColor: true,
              });
            }}
            style={[theme.flex.center, theme.padding.l]}>
            <Text
              size="medium"
              fontFamily="fontInterBold"
              color={theme.basicColor.white}>
              {i18n.t('game-page.label.green').toUpperCase()}
            </Text>
          </NativeTouchableOpacity>
        </LinearGradient>
        <LinearGradient
          colors={disabled ? ['#D2D2D2', '#888888'] : ['#A323F9', '#3700AE']}
          style={[theme.flex.flex1]}>
          <View style={[styles.border]} />
          <NativeTouchableOpacity
            onPress={() => {
              onColorPress({
                digit: 'v',
                color: 'v',
                onlyColor: true,
              });
            }}
            disabled={disabled}
            style={[theme.flex.center, theme.padding.l]}>
            <Text
              size="medium"
              fontFamily="fontInterBold"
              color={theme.basicColor.white}>
              {i18n.t('game-page.label.violet').toUpperCase()}
            </Text>
          </NativeTouchableOpacity>
        </LinearGradient>
        <LinearGradient
          colors={disabled ? ['#D2D2D2', '#888888'] : ['#F50727', '#B20016']}
          style={[theme.flex.flex1, styles.right]}>
          <View style={[styles.border]} />
          <NativeTouchableOpacity
            disabled={disabled}
            onPress={() => {
              onColorPress({
                digit: 'r',
                color: 'r',
                onlyColor: true,
              });
            }}
            style={[theme.flex.center, theme.padding.l]}>
            <Text
              size="medium"
              fontFamily="fontInterBold"
              color={theme.basicColor.white}>
              {i18n.t('game-page.label.red').toUpperCase()}
            </Text>
          </NativeTouchableOpacity>
        </LinearGradient>
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    borderBottomColor: 'rgba(255, 255, 255, 0.20)',
    borderBottomWidth: 1,
  },
  border: {
    position: 'absolute',
    top: 0,
    height: 2,
    left: 0,
    right: 0,
    borderTopLeftRadius: 24,
    backgroundColor: 'rgba(255, 255, 255, 0.20)',
  },
  left: {
    backgroundColor: 'red',
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
  },
  center: {},
  right: {
    backgroundColor: 'red',
    borderTopRightRadius: 24,
    borderBottomRightRadius: 24,
  },
});

export default React.memo(ColorQuick);
