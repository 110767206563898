import React, {useCallback, useRef, useState, useEffect} from 'react';
import {
  Dimensions,
  FlatList,
  RefreshControl,
  ScrollView,
  StyleSheet,
  View,
  ListRenderItemInfo,
} from 'react-native';
import {
  BannerInfo,
  NewGamePageInfo,
  postBannerInfo,
  postNewGameList,
  postNotifications,
  NewGameListItem,
} from './casino.service';
import theme from '@style';
import LazyImage from '@basicComponents/image';
import Text from '@basicComponents/text';
import Card, {lightLinear} from '@basicComponents/card';
import {NoMoreData} from '@basicComponents/default-page';
import DetailNavTitle from '@businessComponents/detail-nav-title';
import globalStore from '@/services/global.state';
import {setDataForSettled} from '@/utils';
import {useFocusEffect} from '@react-navigation/native';
import {useFlatListAutoPlay} from '../hooks/autoplay.hooks';
import CasinoBanner from './casino-banner';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {FadeInView} from '@basicComponents/animations';
import {useAsyncPageSpin} from '../hooks/async.hooks';
import {usePaging} from '../hooks/paging.hooks';
import {BasicObject, NavigatorScreenProps} from '@/types';
import {toGame} from '../game-navigate';
import {useTranslation} from 'react-i18next';
const messageIcon = require('./assets/message.webp');
const gameIcon = require('./assets/game.webp');
import CasinoTabs from './casino-tabs';
import {getCategories} from '@/common-pages/live-casino/live-casino-service';
import NoData from '@/components/basic/error-pages/no-data';
import {goBack} from '@/utils';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {packageId} from '@/config';

const Casino = (props: NavigatorScreenProps) => {
  const isNew = true;
  const {route} = props;
  const {i18n} = useTranslation();
  const categoryId = (route.params as BasicObject)?.categoryId || '5';
  const [bannerList, setBannerList] = useState<BannerInfo[]>([]);
  const [messageList, setMessageList] = useState<string[]>([]);
  const [messageCycle, setMessageCycle] = useState<number>(1);
  const {renderSpin, doAsyncAction} = useAsyncPageSpin();
  const first = useRef(true);
  const [gameType, setGameType] = useState<string>('Lobby');
  const [gameTypes, setGameTypes] = useState<{label: string; value: string}[]>(
    [],
  );
  const {calcActualSize, screenHeight} = useScreenSize();
  const [gameLists, setGameLists] = useState<NewGameListItem[]>([]);
  const curPageNo = useRef(1);
  const totalPage = useRef(1);

  const getGameCategories = () => {
    getCategories(5).then(res => {
      setGameTypes([
        {
          label: 'Lobby',
          value: 'Lobby',
        },
        ...res.map(v => ({
          label: v,
          value: v,
        })),
      ]);
    });
  };
  const handleFocusEffect = useCallback(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      if (token) {
        globalStore.amountCheckOut.next({});
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  useFocusEffect(handleFocusEffect);

  const {
    resultList: gameList,
    init,
    onScroll,
    isEnd,
  } = usePaging(pageNo => {
    return doAsyncAction(() => {
      return postNewGameList({
        pageNo,
        categoryId,
      });
    }) as Promise<NewGamePageInfo>;
  });

  const getList = useCallback(() => {
    return Promise.allSettled([init(), postNotifications(), postBannerInfo()])
      .then(([, notify, banners]) => {
        if (notify.status === 'fulfilled' && notify.value) {
          setMessageList([...notify.value, notify.value[0]]);
          setMessageCycle(notify.value.length);
        }
        setDataForSettled(setBannerList, banners);
      })
      .finally(() => {
        setRefreshing(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getNewList = useCallback(
    (showloading = true) => {
      showloading && globalStore.globalLoading.next(true);
      return postNewGameList({
        categoryId: categoryId,
        pageNo: curPageNo.current,
        gameType: ['Lobby', 'hotGames'].includes(gameType) ? '' : gameType,
        pageSize: 21,
      })
        .then(game => {
          if (curPageNo.current <= 1) {
            setGameLists(game.content || []);
          } else {
            setGameLists([...gameLists, ...(game.content || [])]);
          }

          totalPage.current = game.totalPages;
        })
        .finally(() => {
          globalStore.globalLoading.next(false);
          setRefreshing(false);
        });
    },
    [categoryId, gameLists, gameType],
  );
  const refreshNextPage = () => {
    if (totalPage.current === 1) {
      return;
    }
    curPageNo.current++;
    if (curPageNo.current > totalPage.current) {
      return;
    }
    globalStore.globalLoading.next(true);
    getNewList(true);
  };
  const size = {
    computedS: calcActualSize(theme.paddingSize.s),
    cardWidth: calcActualSize(111.66),
    cardHeight: calcActualSize(155.66),
    computedL: calcActualSize(theme.paddingSize.l),
  };

  const handleInit = useCallback(() => {
    doAsyncAction(getList, first.current);
    first.current = false;
    const sub = globalStore.tokenSubject.subscribe(token => {
      if (token) {
        globalStore.updateAmount.next({});
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [doAsyncAction, getList]);
  useFocusEffect(handleInit);

  const gameCardWidth =
    (Dimensions.get('window').width -
      2 * theme.paddingSize.l -
      theme.paddingSize.s) /
    2;

  const {flatListRef: messageRef} = useFlatListAutoPlay(messageList, {
    canPlay: true,
    overListLength: 1,
    timeout: 3000,
    row: false,
    cycle: messageCycle,
    size: theme.iconSize.m,
  });

  const [refreshing, setRefreshing] = useState<boolean>(false);

  const renderItem = ({
    item: game,
    index,
  }: ListRenderItemInfo<NewGameListItem>) => {
    return (
      <View key={index}>
        <NativeTouchableOpacity onPress={() => toGame(game)}>
          <Card
            key={index}
            radius={theme.borderRadiusSize.m}
            backgroundColor={theme.basicColor.white}>
            <Card.Image
              imageUrl={game.otherUrl || game.gamePic}
              width={size.cardWidth}
              height={size.cardHeight}
              resizeMode="cover"
            />
          </Card>
        </NativeTouchableOpacity>
      </View>
    );
  };

  useEffect(() => {
    isNew && getGameCategories();
  }, [isNew]);
  useEffect(() => {
    curPageNo.current = 1;
    isNew && getNewList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameType]);
  return (
    <>
      {isNew ? (
        <FadeInView
          style={[{height: screenHeight}, theme.background.lightGrey]}>
          <DetailNavTitle
            title={i18n.t('home.tab.casino')}
            hideServer
            onBack={packageId !== 3 ? goBack : undefined}
          />
          <CasinoTabs
            tabOptions={gameTypes}
            value={gameType}
            onChange={setGameType}
          />

          <FlatList
            style={[theme.flex.flex1]}
            data={gameLists}
            renderItem={renderItem}
            refreshControl={
              <RefreshControl
                refreshing={refreshing}
                onRefresh={() => {
                  setRefreshing(true);
                  getNewList(false);
                }}
              />
            }
            ListFooterComponentStyle={[theme.fill.fillW, theme.flex.center]}
            contentContainerStyle={[
              theme.flex.row,
              theme.flex.wrap,
              theme.flex.start,
              {
                gap: size.computedS,
                paddingHorizontal: size.computedL,
              },
            ]}
            onEndReached={refreshNextPage}
            ListEmptyComponent={
              <View
                style={[
                  theme.padding.xxl,
                  theme.flex.center,
                  theme.fill.fillW,
                ]}>
                <NoData />
              </View>
            }
            ListFooterComponent={
              gameLists &&
              gameLists.length > 0 &&
              curPageNo.current >= totalPage.current ? (
                <NoMoreData />
              ) : undefined
            }
          />
        </FadeInView>
      ) : (
        <FadeInView>
          {renderSpin(
            <>
              <DetailNavTitle title={i18n.t('home.tab.casino')} />
              <ScrollView
                style={[theme.flex.flex1]}
                refreshControl={
                  <RefreshControl
                    refreshing={refreshing}
                    onRefresh={() => {
                      setRefreshing(true);
                      getList();
                    }}
                  />
                }
                scrollEventThrottle={16}
                onScroll={onScroll}>
                {isNew && (
                  <CasinoTabs
                    tabOptions={gameTypes}
                    value={gameType}
                    onChange={setGameType}
                  />
                )}
                {!isNew && <CasinoBanner bannerList={bannerList} />}
                {messageList?.length > 0 && (
                  <View
                    style={[
                      theme.flex.row,
                      theme.flex.centerByCol,
                      theme.margin.lrl,
                      theme.padding.lrl,
                      theme.padding.tbs,
                      theme.background.white,
                      theme.borderRadius.m,
                    ]}>
                    <LazyImage
                      imageUrl={messageIcon}
                      width={theme.iconSize.m}
                      height={theme.iconSize.m}
                      occupancy={'transparent'}
                    />
                    <FlatList
                      data={messageList}
                      ref={messageRef}
                      scrollEnabled={false}
                      style={[{height: theme.iconSize.m}]}
                      renderItem={({item, index}) => (
                        <Text
                          fontSize={theme.fontSize.m}
                          key={index}
                          style={[theme.font.second, theme.margin.lefts]}>
                          {item}
                        </Text>
                      )}
                    />
                  </View>
                )}
                <View style={[messageList?.length > 0 && theme.margin.topl]}>
                  <Card
                    radius={theme.borderRadiusSize.m}
                    backgroundColor={theme.backgroundColor.lightGrey}>
                    <Card.Title
                      icon={{
                        url: gameIcon,
                        width: theme.iconSize.m,
                        height: theme.iconSize.m,
                      }}
                      title="Games Lobby"
                      style={[styles.cardTitle]}
                      linear={lightLinear}
                    />
                    <View
                      style={[
                        theme.padding.lrl,
                        theme.flex.row,
                        theme.flex.wrap,
                        theme.flex.between,
                      ]}>
                      {gameList.map((game, index) => (
                        <View style={[theme.margin.btms]} key={index}>
                          <NativeTouchableOpacity onPress={() => toGame(game)}>
                            <Card
                              key={index}
                              radius={theme.borderRadiusSize.m}
                              backgroundColor={theme.basicColor.white}>
                              <Card.Image
                                imageUrl={game.gamePic}
                                width={gameCardWidth}
                                height={(gameCardWidth / 172) * 114}
                              />
                              <View
                                style={[
                                  theme.padding.lrs,
                                  styles.smallCardContent,
                                  theme.flex.col,
                                  theme.flex.alignStart,
                                  theme.flex.centerByRow,
                                ]}>
                                <Text
                                  fontSize={theme.fontSize.s}
                                  blod
                                  second
                                  style={[
                                    {
                                      maxWidth:
                                        gameCardWidth -
                                        theme.paddingSize.s * 2 -
                                        1,
                                    },
                                  ]}
                                  numberOfLines={1}
                                  ellipsizeMode={'tail'}>
                                  {game.name}
                                </Text>
                                <Text
                                  fontSize={theme.fontSize.xs}
                                  style={[theme.font.secAccent]}>
                                  {game.provider || '-'}
                                </Text>
                              </View>
                            </Card>
                          </NativeTouchableOpacity>
                        </View>
                      ))}
                    </View>
                  </Card>
                </View>
                {isEnd && <NoMoreData />}
              </ScrollView>
            </>,
          )}
        </FadeInView>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  cardTitle: {
    height: 42,
  },
  smallCardContent: {
    height: 40,
  },
});

export default Casino;
