import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {
  ClassicIcon,
  LobbyIcon,
  NewReleasesIcon,
  SlotsIcon,
  TableGamesIcon,
  OthersIcon,
} from './svg.variable';
import theme from '@/style';
import React, {useMemo} from 'react';
import Tabs from '@/components/basic/tab';
import {View} from 'react-native';
import Text from '@/components/basic/text';
import LinearGradient from '@/components/basic/linear-gradient/linear-gradient';

interface IconsProps {
  name?: string;
  checked?: boolean;
}

export interface CasinoTabsProps {
  tabOptions: {label: string; value: string}[];
  value: string;
  onChange: (value: string) => void;
}

const Icons: React.FC<IconsProps> = ({checked = false, name = ''}) => {
  const {calcActualSize} = useScreenSize();
  const innerProps = {
    width: calcActualSize(14),
    height: calcActualSize(14),
    fill: checked ? theme.fontColor.main : theme.fontColor.accent,
  };
  if (name === '' || name.toLowerCase().trim().startsWith('lobby')) {
    return <LobbyIcon {...innerProps} />;
  }
  if (name.toLowerCase().trim().startsWith('classic')) {
    return <ClassicIcon {...innerProps} />;
  }
  if (name.toLowerCase().trim().startsWith('slots')) {
    return <SlotsIcon {...innerProps} />;
  }
  if (name.toLowerCase().trim().startsWith('table games')) {
    return <TableGamesIcon {...innerProps} />;
  }
  if (name.toLowerCase().trim().startsWith('new games')) {
    return <NewReleasesIcon {...innerProps} />;
  }
  if (name.toLowerCase().trim().startsWith('others')) {
    return <OthersIcon {...innerProps} />;
  }
  return null;
};

const CasinoTabs: React.FC<CasinoTabsProps> = ({
  tabOptions,
  value,
  onChange,
}) => {
  const resultTabOptions = useMemo(() => {
    return tabOptions.map(({label, value: innerValue}) => ({
      // eslint-disable-next-line react/no-unstable-nested-components
      label: (active: boolean) => (
        <View
          style={[
            theme.flex.row,
            theme.flex.center,
            theme.padding.lrs,
            theme.padding.tbxxs,
            theme.borderRadius.xs,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              backgroundColor: active ? '#F6F7FB' : theme.basicColor.white,
              height: 24,
            },
          ]}>
          <Icons name={innerValue} checked={active} />
          <Text
            main={active}
            accent={!active}
            blod={active}
            calc
            style={theme.margin.leftxxs}>
            {label}
          </Text>
        </View>
      ),
      value: innerValue,
    }));
  }, [tabOptions]);
  return (
    <LinearGradient
      start={{x: 0, y: 0}}
      end={{x: 0, y: 1}}
      colors={['#fff', 'rgba(255, 255, 255, 0.00)']}>
      <Tabs
        style={[theme.background.transparent]}
        tabOptions={resultTabOptions}
        value={value}
        onChange={onChange}
        innerGap={theme.paddingSize.xxs}
        noShadow
      />
    </LinearGradient>
  );
};

export default CasinoTabs;
